// @types
import {
  GetBackupTimestampsResponseDTO,
  CompletedUpdatesResponse,
} from '@joonasvanhatapio/wp-cloud-backend-types';
import {
  CommunityCardContent,
  CommunityCardContentType,
  MWPUpdateStatusEnum,
  PreviewCardContent,
  UpdateVulnerabilityRow,
  UpdateVulnerabilityType,
  MWPSiteStatusEnum,
  MWPSiteIssueEnum,
  MWPSiteStatusDetails,
} from 'src/@types/site';

// ----------------------------------------------------------------------
// DETAILS/WPONE/UPDATES

export const DUMMY_UPDATES_DATA: CompletedUpdatesResponse = {
  site_url: 'www.teemutestaa.fi',
  nextUpdate: new Date('2024-09-03T06:00:36.646Z'),
  lastUpdate: new Date('2024-09-10T15:55:05.000Z'),
  managedThemes: 0,
  managedPlugins: 0,
  updateList: [
    // NONE STATUS
    // 1. No update
    {
      id: '1',
      site_url: 'www.teemutestaa.fi',
      createdAt: new Date('2024-09-10T15:55:05.637Z'),
      next_update: new Date('2024-09-11T15:55:32.000Z'),
      update_attempted_on: new Date('2024-09-10T15:55:32.000Z'),
      updatedAt: new Date('2024-09-10T15:55:32.023Z'),
      total_updates: 0,
      updated_wp: null,
      updated_themes: null,
      updated_plugins: null,
    },
    // FAILED STATUS
    // 2. Only core failed
    {
      id: '2',
      site_url: 'www.teemutestaa.fi',
      createdAt: new Date('2024-09-10T15:55:05.637Z'),
      next_update: new Date('2024-09-11T15:55:32.000Z'),
      update_attempted_on: new Date('2024-09-10T15:55:32.000Z'),
      updatedAt: new Date('2024-09-10T15:55:32.023Z'),
      total_updates: 0,
      updated_wp: {
        start_time: new Date('Tue, 10 Sep 2024 15:55:29 GMT'),
        reverted: true,
        version_from: '6.6.1',
        version_to: '6.6.1',
      },
      updated_themes: null,
      updated_plugins: null,
    },
    // 3. Only themes failed (successful 0/2)
    {
      id: '3',
      site_url: 'www.teemutestaa.fi',
      createdAt: new Date('2024-09-10T15:55:05.637Z'),
      next_update: new Date('2024-09-11T15:55:32.000Z'),
      update_attempted_on: new Date('2024-09-10T15:55:32.000Z'),
      updatedAt: new Date('2024-09-10T15:55:32.023Z'),
      total_updates: 0,
      updated_wp: null,
      updated_themes: {
        keyName1: {
          title: 'Twenty Twenty-Two',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          reverted: true,
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
        keyName2: {
          title: 'Divi',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          reverted: true,
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
      },
      updated_plugins: null,
    },
    // 4. Only plugins failed (successful 0/2)
    {
      id: '4',
      site_url: 'www.teemutestaa.fi',
      createdAt: new Date('2024-09-10T15:55:05.637Z'),
      next_update: new Date('2024-09-11T15:55:32.000Z'),
      update_attempted_on: new Date('2024-09-10T15:55:32.000Z'),
      updatedAt: new Date('2024-09-10T15:55:32.023Z'),
      total_updates: 0,
      updated_wp: null,
      updated_themes: null,
      updated_plugins: {
        keyName1: {
          title: 'Imagify',
          version_attempted: '2.2.2',
          start_time: new Date('Mon, 02 Sep 2024 06:04:47 GMT'),
          reverted: true,
          version_from: '2.0',
          version_to: '2.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/homePage.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/homePage.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/homePage.png',
                },
              ],
            },
            {
              id: 'noremove',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/noremove.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/noremove.png',
              url: 'https://teemutestaa.fi/',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/noremove.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/noremove.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/noremove.png',
                },
              ],
            },
          ],
        },
        keyName2: {
          title: 'Imagify2',
          version_attempted: '2.2.2',
          start_time: new Date('Mon, 02 Sep 2024 06:04:47 GMT'),
          reverted: true,
          version_from: '2.0',
          version_to: '2.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/homePage.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/homePage.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/homePage.png',
                },
              ],
            },
            {
              id: 'noremove',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/noremove.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/noremove.png',
              url: 'https://teemutestaa.fi/',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/noremove.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/noremove.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/noremove.png',
                },
              ],
            },
          ],
        },
      },
    },
    // 5. Only core and themes failed (themes successful 0/2)
    {
      id: '5',
      site_url: 'www.teemutestaa.fi',
      createdAt: new Date('2024-09-10T15:55:05.637Z'),
      next_update: new Date('2024-09-11T15:55:32.000Z'),
      update_attempted_on: new Date('2024-09-10T15:55:32.000Z'),
      updatedAt: new Date('2024-09-10T15:55:32.023Z'),
      total_updates: 0,
      updated_wp: {
        start_time: new Date('Tue, 10 Sep 2024 15:55:29 GMT'),
        reverted: true,
        version_from: '6.6.1',
        version_to: '6.6.1',
      },
      updated_themes: {
        keyName1: {
          title: 'Twenty Twenty-Two',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          reverted: true,
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
        keyName2: {
          title: 'Divi',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          reverted: true,
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
      },
      updated_plugins: null,
    },
    // 6. Only core and plugins failed (plugins successful 0/2)
    {
      id: '6',
      site_url: 'www.teemutestaa.fi',
      createdAt: new Date('2024-09-10T15:55:05.637Z'),
      next_update: new Date('2024-09-11T15:55:32.000Z'),
      update_attempted_on: new Date('2024-09-10T15:55:32.000Z'),
      updatedAt: new Date('2024-09-10T15:55:32.023Z'),
      total_updates: 0,
      updated_wp: {
        start_time: new Date('Tue, 10 Sep 2024 15:55:29 GMT'),
        reverted: true,
        version_from: '6.6.1',
        version_to: '6.6.1',
      },
      updated_themes: null,
      updated_plugins: {
        keyName1: {
          title: 'Imagify',
          version_attempted: '2.2.2',
          start_time: new Date('Mon, 02 Sep 2024 06:04:47 GMT'),
          reverted: true,
          version_from: '2.0',
          version_to: '2.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/homePage.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/homePage.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/homePage.png',
                },
              ],
            },
            {
              id: 'noremove',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/noremove.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/noremove.png',
              url: 'https://teemutestaa.fi/',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/noremove.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/noremove.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/noremove.png',
                },
              ],
            },
          ],
        },
        keyName2: {
          title: 'Imagify2',
          version_attempted: '2.2.2',
          start_time: new Date('Mon, 02 Sep 2024 06:04:47 GMT'),
          reverted: true,
          version_from: '2.0',
          version_to: '2.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/homePage.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/homePage.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/homePage.png',
                },
              ],
            },
            {
              id: 'noremove',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/noremove.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/noremove.png',
              url: 'https://teemutestaa.fi/',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/noremove.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/noremove.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/noremove.png',
                },
              ],
            },
          ],
        },
      },
    },
    // 7. Only themes and plugins failed (themes successful 0/2 - plugins successful 0/2)
    {
      id: '7',
      site_url: 'www.teemutestaa.fi',
      createdAt: new Date('2024-09-10T15:55:05.637Z'),
      next_update: new Date('2024-09-11T15:55:32.000Z'),
      update_attempted_on: new Date('2024-09-10T15:55:32.000Z'),
      updatedAt: new Date('2024-09-10T15:55:32.023Z'),
      total_updates: 0,
      updated_wp: null,
      updated_themes: {
        keyName1: {
          title: 'Twenty Twenty-Two',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          reverted: true,
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
        keyName2: {
          title: 'Divi',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          reverted: true,
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
      },
      updated_plugins: {
        keyName1: {
          title: 'Imagify',
          version_attempted: '2.2.2',
          start_time: new Date('Mon, 02 Sep 2024 06:04:47 GMT'),
          reverted: true,
          version_from: '2.0',
          version_to: '2.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/homePage.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/homePage.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/homePage.png',
                },
              ],
            },
            {
              id: 'noremove',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/noremove.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/noremove.png',
              url: 'https://teemutestaa.fi/',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/noremove.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/noremove.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/noremove.png',
                },
              ],
            },
          ],
        },
        keyName2: {
          title: 'Imagify2',
          version_attempted: '2.2.2',
          start_time: new Date('Mon, 02 Sep 2024 06:04:47 GMT'),
          reverted: true,
          version_from: '2.0',
          version_to: '2.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/homePage.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/homePage.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/homePage.png',
                },
              ],
            },
            {
              id: 'noremove',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/noremove.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/noremove.png',
              url: 'https://teemutestaa.fi/',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/noremove.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/noremove.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/noremove.png',
                },
              ],
            },
          ],
        },
      },
    },
    // 8. All three failed (themes successful 0/2 - plugins successful 0/2)
    {
      id: '8',
      site_url: 'www.teemutestaa.fi',
      createdAt: new Date('2024-09-10T15:55:05.637Z'),
      next_update: new Date('2024-09-11T15:55:32.000Z'),
      update_attempted_on: new Date('2024-09-10T15:55:32.000Z'),
      updatedAt: new Date('2024-09-10T15:55:32.023Z'),
      total_updates: 0,
      updated_wp: {
        start_time: new Date('Tue, 10 Sep 2024 15:55:29 GMT'),
        reverted: true,
        version_from: '6.6.1',
        version_to: '6.6.1',
      },
      updated_themes: {
        keyName1: {
          title: 'Twenty Twenty-Two',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          reverted: true,
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
        keyName2: {
          title: 'Divi',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          reverted: true,
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
      },
      updated_plugins: {
        keyName1: {
          title: 'Imagify',
          version_attempted: '2.2.2',
          start_time: new Date('Mon, 02 Sep 2024 06:04:47 GMT'),
          reverted: true,
          version_from: '2.0',
          version_to: '2.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/homePage.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/homePage.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/homePage.png',
                },
              ],
            },
            {
              id: 'noremove',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/noremove.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/noremove.png',
              url: 'https://teemutestaa.fi/',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/noremove.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/noremove.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/noremove.png',
                },
              ],
            },
          ],
        },
        keyName2: {
          title: 'Imagify2',
          version_attempted: '2.2.2',
          start_time: new Date('Mon, 02 Sep 2024 06:04:47 GMT'),
          reverted: true,
          version_from: '2.0',
          version_to: '2.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/homePage.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/homePage.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/homePage.png',
                },
              ],
            },
            {
              id: 'noremove',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/noremove.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/noremove.png',
              url: 'https://teemutestaa.fi/',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/noremove.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/noremove.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/noremove.png',
                },
              ],
            },
          ],
        },
      },
    },
    // GOOD AND WARNING STATUS
    // 9. Only core has update (successful)
    {
      id: '9',
      site_url: 'www.teemutestaa.fi',
      createdAt: new Date('2024-09-10T15:55:05.637Z'),
      next_update: new Date('2024-09-11T15:55:32.000Z'),
      update_attempted_on: new Date('2024-09-10T15:55:32.000Z'),
      updatedAt: new Date('2024-09-10T15:55:32.023Z'),
      total_updates: 0,
      updated_wp: {
        start_time: new Date('Tue, new Date10 Sep 2024 15:55:29 GMT'),
        version_from: '6.6.1',
        version_to: '6.6.1',
      },
      updated_themes: null,
      updated_plugins: null,
    },
    // 10. Only themes has update (successful 2/2)
    {
      id: '10',
      site_url: 'www.teemutestaa.fi',
      createdAt: new Date('2024-09-10T15:55:05.637Z'),
      next_update: new Date('2024-09-11T15:55:32.000Z'),
      update_attempted_on: new Date('2024-09-10T15:55:32.000Z'),
      updatedAt: new Date('2024-09-10T15:55:32.023Z'),
      total_updates: 0,
      updated_wp: null,
      updated_themes: {
        keyName1: {
          title: 'Twenty Twenty-Two',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
        keyName2: {
          title: 'Divi',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
      },
      updated_plugins: null,
    },
    // 11. Only plugins has update (successful 2/2)
    {
      id: '11',
      site_url: 'www.teemutestaa.fi',
      createdAt: new Date('2024-09-10T15:55:05.637Z'),
      next_update: new Date('2024-09-11T15:55:32.000Z'),
      update_attempted_on: new Date('2024-09-10T15:55:32.000Z'),
      updatedAt: new Date('2024-09-10T15:55:32.023Z'),
      total_updates: 0,
      updated_wp: null,
      updated_themes: null,
      updated_plugins: {
        keyName1: {
          title: 'Imagify',
          version_attempted: '2.2.2',
          start_time: new Date('Mon, 02 Sep 2024 06:04:47 GMT'),
          version_from: '2.0',
          version_to: '2.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/homePage.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/homePage.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/homePage.png',
                },
              ],
            },
            {
              id: 'noremove',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/noremove.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/noremove.png',
              url: 'https://teemutestaa.fi/',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/noremove.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/noremove.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/noremove.png',
                },
              ],
            },
          ],
        },
        keyName2: {
          title: 'Imagify2',
          version_attempted: '2.2.2',
          start_time: new Date('Mon, 02 Sep 2024 06:04:47 GMT'),
          version_from: '2.0',
          version_to: '2.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/homePage.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/homePage.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/homePage.png',
                },
              ],
            },
            {
              id: 'noremove',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/noremove.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/noremove.png',
              url: 'https://teemutestaa.fi/',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/noremove.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/noremove.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/noremove.png',
                },
              ],
            },
          ],
        },
      },
    },
    // 12. Only core and plugins have update (core successful - plugins successful 2/2)
    {
      id: '12',
      site_url: 'www.teemutestaa.fi',
      createdAt: new Date('2024-09-10T15:55:05.637Z'),
      next_update: new Date('2024-09-11T15:55:32.000Z'),
      update_attempted_on: new Date('2024-09-10T15:55:32.000Z'),
      updatedAt: new Date('2024-09-10T15:55:32.023Z'),
      total_updates: 0,
      updated_wp: {
        start_time: new Date(new Date('Tue, new Date10 Sep 2024 15:55:29 GMT')),
        version_from: '6.6.1',
        version_to: '6.6.1',
      },
      updated_themes: null,
      updated_plugins: {
        keyName1: {
          title: 'Imagify',
          version_attempted: '2.2.2',
          start_time: new Date('Mon, 02 Sep 2024 06:04:47 GMT'),
          version_from: '2.0',
          version_to: '2.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/homePage.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/homePage.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/homePage.png',
                },
              ],
            },
            {
              id: 'noremove',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/noremove.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/noremove.png',
              url: 'https://teemutestaa.fi/',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/noremove.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/noremove.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/noremove.png',
                },
              ],
            },
          ],
        },
        keyName2: {
          title: 'Imagify2',
          version_attempted: '2.2.2',
          start_time: new Date('Mon, 02 Sep 2024 06:04:47 GMT'),
          version_from: '2.0',
          version_to: '2.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/homePage.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/homePage.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/homePage.png',
                },
              ],
            },
            {
              id: 'noremove',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/noremove.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/noremove.png',
              url: 'https://teemutestaa.fi/',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/noremove.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/noremove.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/noremove.png',
                },
              ],
            },
          ],
        },
      },
    },
    // 13. Only core and plugins have update (core failed - plugins successful 2/2)
    {
      id: '13',
      site_url: 'www.teemutestaa.fi',
      createdAt: new Date('2024-09-10T15:55:05.637Z'),
      next_update: new Date('2024-09-11T15:55:32.000Z'),
      update_attempted_on: new Date('2024-09-10T15:55:32.000Z'),
      updatedAt: new Date('2024-09-10T15:55:32.023Z'),
      total_updates: 0,
      updated_wp: {
        start_time: new Date('Tue, 10 Sep 2024 15:55:29 GMT'),
        reverted: true,
        version_from: '6.6.1',
        version_to: '6.6.1',
      },
      updated_themes: null,
      updated_plugins: {
        keyName1: {
          title: 'Imagify',
          version_attempted: '2.2.2',
          start_time: new Date('Mon, 02 Sep 2024 06:04:47 GMT'),
          version_from: '2.0',
          version_to: '2.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
            {
              id: 'noremove',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/noremove.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/noremove.png',
              url: 'https://teemutestaa.fi/',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/noremove.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/noremove.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/noremove.png',
                },
              ],
            },
          ],
        },
        keyName2: {
          title: 'Imagify',
          version_attempted: '2.2.2',
          start_time: new Date('Mon, 02 Sep 2024 06:04:47 GMT'),
          version_from: '2.0',
          version_to: '2.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
            {
              id: 'noremove',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/noremove.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/noremove.png',
              url: 'https://teemutestaa.fi/',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/noremove.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/noremove.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/noremove.png',
                },
              ],
            },
          ],
        },
      },
    },
    // 14. Only core and themes have update (core successful - themes successful 2/2)
    {
      id: '14',
      site_url: 'www.teemutestaa.fi',
      createdAt: new Date('2024-09-10T15:55:05.637Z'),
      next_update: new Date('2024-09-11T15:55:32.000Z'),
      update_attempted_on: new Date('2024-09-10T15:55:32.000Z'),
      updatedAt: new Date('2024-09-10T15:55:32.023Z'),
      total_updates: 0,
      updated_wp: {
        start_time: new Date('Tue, new Date10 Sep 2024 15:55:29 GMT'),
        version_from: '6.6.1',
        version_to: '6.6.1',
      },
      updated_themes: {
        keyName1: {
          title: 'Twenty Twenty-Two',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
        keyName2: {
          title: 'Divi',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
      },
      updated_plugins: null,
    },
    // 15. Only core and themes have update (core failed - themes successful 2/2)
    {
      id: '15',
      site_url: 'www.teemutestaa.fi',
      createdAt: new Date('2024-09-10T15:55:05.637Z'),
      next_update: new Date('2024-09-11T15:55:32.000Z'),
      update_attempted_on: new Date('2024-09-10T15:55:32.000Z'),
      updatedAt: new Date('2024-09-10T15:55:32.023Z'),
      total_updates: 0,
      updated_wp: {
        start_time: new Date('Tue, 10 Sep 2024 15:55:29 GMT'),
        reverted: true,
        version_from: '6.6.1',
        version_to: '6.6.1',
      },
      updated_themes: {
        keyName1: {
          title: 'Twenty Twenty-Two',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
        keyName2: {
          title: 'Divi',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
      },
      updated_plugins: null,
    },
    // 16. Only core and themes have update (core failed - themes successful 1/2)
    {
      id: '16',
      site_url: 'www.teemutestaa.fi',
      createdAt: new Date('2024-09-10T15:55:05.637Z'),
      next_update: new Date('2024-09-11T15:55:32.000Z'),
      update_attempted_on: new Date('2024-09-10T15:55:32.000Z'),
      updatedAt: new Date('2024-09-10T15:55:32.023Z'),
      total_updates: 0,
      updated_wp: {
        start_time: new Date('Tue, 10 Sep 2024 15:55:29 GMT'),
        reverted: true,
        version_from: '6.6.1',
        version_to: '6.6.1',
      },
      updated_themes: {
        keyName1: {
          title: 'Twenty Twenty-Two',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          reverted: true,
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
        keyName2: {
          title: 'Divi',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
      },
      updated_plugins: null,
    },
    // 17. Only themes and plugins have update (themes successful 2/2 - plugins successful 2/2)
    {
      id: '17',
      site_url: 'www.teemutestaa.fi',
      createdAt: new Date('2024-09-10T15:55:05.637Z'),
      next_update: new Date('2024-09-11T15:55:32.000Z'),
      update_attempted_on: new Date('2024-09-10T15:55:32.000Z'),
      updatedAt: new Date('2024-09-10T15:55:32.023Z'),
      total_updates: 0,
      updated_wp: null,
      updated_themes: {
        keyName1: {
          title: 'Twenty Twenty-Two',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
        keyName2: {
          title: 'Divi',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
      },
      updated_plugins: {
        keyName1: {
          title: 'Imagify',
          version_attempted: '2.2.2',
          start_time: new Date('Mon, 02 Sep 2024 06:04:47 GMT'),
          version_from: '2.0',
          version_to: '2.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/homePage.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/homePage.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/homePage.png',
                },
              ],
            },
            {
              id: 'noremove',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/noremove.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/noremove.png',
              url: 'https://teemutestaa.fi/',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/noremove.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/noremove.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/noremove.png',
                },
              ],
            },
          ],
        },
        keyName2: {
          title: 'Imagify2',
          version_attempted: '2.2.2',
          start_time: new Date('Mon, 02 Sep 2024 06:04:47 GMT'),
          version_from: '2.0',
          version_to: '2.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/homePage.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/homePage.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/homePage.png',
                },
              ],
            },
            {
              id: 'noremove',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/noremove.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/noremove.png',
              url: 'https://teemutestaa.fi/',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/noremove.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/noremove.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/noremove.png',
                },
              ],
            },
          ],
        },
      },
    },
    // 18. Only themes and plugins have update (themes successful 1/2 - plugins successful 2/2)
    {
      id: '18',
      site_url: 'www.teemutestaa.fi',
      createdAt: new Date('2024-09-10T15:55:05.637Z'),
      next_update: new Date('2024-09-11T15:55:32.000Z'),
      update_attempted_on: new Date('2024-09-10T15:55:32.000Z'),
      updatedAt: new Date('2024-09-10T15:55:32.023Z'),
      total_updates: 0,
      updated_wp: null,
      updated_themes: {
        keyName1: {
          title: 'Twenty Twenty-Two',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
        keyName2: {
          title: 'Divi',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          reverted: true,
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
      },
      updated_plugins: {
        keyName1: {
          title: 'Imagify',
          version_attempted: '2.2.2',
          start_time: new Date('Mon, 02 Sep 2024 06:04:47 GMT'),
          version_from: '2.0',
          version_to: '2.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/homePage.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/homePage.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/homePage.png',
                },
              ],
            },
            {
              id: 'noremove',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/noremove.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/noremove.png',
              url: 'https://teemutestaa.fi/',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/noremove.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/noremove.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/noremove.png',
                },
              ],
            },
          ],
        },
        keyName2: {
          title: 'Imagify2',
          version_attempted: '2.2.2',
          start_time: new Date('Mon, 02 Sep 2024 06:04:47 GMT'),
          version_from: '2.0',
          version_to: '2.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/homePage.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/homePage.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/homePage.png',
                },
              ],
            },
            {
              id: 'noremove',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/noremove.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/noremove.png',
              url: 'https://teemutestaa.fi/',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/noremove.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/noremove.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/noremove.png',
                },
              ],
            },
          ],
        },
      },
    },
    // 19. All three have update (core successful - themes successful 2/2 - plugins successful 2/2)
    {
      id: '19',
      site_url: 'www.teemutestaa.fi',
      createdAt: new Date('2024-09-10T15:55:05.637Z'),
      next_update: new Date('2024-09-11T15:55:32.000Z'),
      update_attempted_on: new Date('2024-09-10T15:55:32.000Z'),
      updatedAt: new Date('2024-09-10T15:55:32.023Z'),
      total_updates: 0,
      updated_wp: {
        start_time: new Date('Tue, new Date10 Sep 2024 15:55:29 GMT'),
        version_from: '6.6.1',
        version_to: '6.6.1',
      },
      updated_themes: {
        keyName1: {
          title: 'Twenty Twenty-Two',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
        keyName2: {
          title: 'Divi',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
      },
      updated_plugins: {
        keyName1: {
          title: 'Imagify',
          version_attempted: '2.2.2',
          start_time: new Date('Mon, 02 Sep 2024 06:04:47 GMT'),
          version_from: '2.0',
          version_to: '2.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/homePage.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/homePage.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/homePage.png',
                },
              ],
            },
            {
              id: 'noremove',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/noremove.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/noremove.png',
              url: 'https://teemutestaa.fi/',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/noremove.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/noremove.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/noremove.png',
                },
              ],
            },
          ],
        },
        keyName2: {
          title: 'Imagify2',
          version_attempted: '2.2.2',
          start_time: new Date('Mon, 02 Sep 2024 06:04:47 GMT'),
          version_from: '2.0',
          version_to: '2.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/homePage.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/homePage.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/homePage.png',
                },
              ],
            },
            {
              id: 'noremove',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/noremove.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/noremove.png',
              url: 'https://teemutestaa.fi/',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/noremove.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/noremove.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/noremove.png',
                },
              ],
            },
          ],
        },
      },
    },
    // 20. All three have update (core failed - themes successful 2/2 - plugins successful 2/2)
    {
      id: '20',
      site_url: 'www.teemutestaa.fi',
      createdAt: new Date('2024-09-10T15:55:05.637Z'),
      next_update: new Date('2024-09-11T15:55:32.000Z'),
      update_attempted_on: new Date('2024-09-10T15:55:32.000Z'),
      updatedAt: new Date('2024-09-10T15:55:32.023Z'),
      total_updates: 0,
      updated_wp: {
        start_time: new Date('Tue, 10 Sep 2024 15:55:29 GMT'),
        reverted: true,
        version_from: '6.6.1',
        version_to: '6.6.1',
      },
      updated_themes: {
        keyName1: {
          title: 'Twenty Twenty-Two',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
        keyName2: {
          title: 'Divi',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
      },
      updated_plugins: {
        keyName1: {
          title: 'Imagify',
          version_attempted: '2.2.2',
          start_time: new Date('Mon, 02 Sep 2024 06:04:47 GMT'),
          version_from: '2.0',
          version_to: '2.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/homePage.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/homePage.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/homePage.png',
                },
              ],
            },
            {
              id: 'noremove',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/noremove.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/noremove.png',
              url: 'https://teemutestaa.fi/',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/noremove.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/noremove.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/noremove.png',
                },
              ],
            },
          ],
        },
        keyName2: {
          title: 'Imagify',
          version_attempted: '2.2.2',
          start_time: new Date('Mon, 02 Sep 2024 06:04:47 GMT'),
          version_from: '2.0',
          version_to: '2.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/homePage.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/homePage.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/homePage.png',
                },
              ],
            },
            {
              id: 'noremove',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/noremove.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/noremove.png',
              url: 'https://teemutestaa.fi/',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/noremove.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/noremove.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/noremove.png',
                },
              ],
            },
          ],
        },
      },
    },
    // 21. All three have update (core failed - themes successful 1/2 - plugins successful 0/2)
    {
      id: '21',
      site_url: 'www.teemutestaa.fi',
      createdAt: new Date('2024-09-10T15:55:05.637Z'),
      next_update: new Date('2024-09-11T15:55:32.000Z'),
      update_attempted_on: new Date('2024-09-10T15:55:32.000Z'),
      updatedAt: new Date('2024-09-10T15:55:32.023Z'),
      total_updates: 0,
      updated_wp: {
        start_time: new Date('Tue, 10 Sep 2024 15:55:29 GMT'),
        reverted: true,
        version_from: '6.6.1',
        version_to: '6.6.1',
      },
      updated_themes: {
        keyName1: {
          title: 'Twenty Twenty-Two',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          reverted: true,
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
        keyName2: {
          title: 'Divi',
          version_attempted: '1.7',
          start_time: new Date('Mon, 13 May 2024 06:19:43 GMT'),
          version_from: '1.0',
          version_to: '1.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/968327857690345493/diffs/after_twentytwentytwo_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
            },
          ],
        },
      },
      updated_plugins: {
        keyName1: {
          title: 'Imagify',
          version_attempted: '2.2.2',
          start_time: new Date('Mon, 02 Sep 2024 06:04:47 GMT'),
          reverted: true,
          version_from: '2.0',
          version_to: '2.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/homePage.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/homePage.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/homePage.png',
                },
              ],
            },
            {
              id: 'noremove',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/noremove.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/noremove.png',
              url: 'https://teemutestaa.fi/',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/noremove.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/noremove.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/noremove.png',
                },
              ],
            },
          ],
        },
        keyName2: {
          title: 'Imagify2',
          version_attempted: '2.2.2',
          start_time: new Date('Mon, 02 Sep 2024 06:04:47 GMT'),
          reverted: true,
          version_from: '2.0',
          version_to: '2.0',
          visual_diffs: [
            {
              id: 'homePage',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/homePage.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/homePage.png',
              url: 'https://teemutestaa.fi',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/homePage.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/homePage.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/homePage.png',
                },
              ],
            },
            {
              id: 'noremove',
              file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/noremove.jpeg',
              thumb:
                'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/after_imagify_update/thumbs/noremove.png',
              url: 'https://teemutestaa.fi/',
              updateSteps: [
                {
                  afterUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/after_imagify_update/noremove.png',
                  beforeUpdate:
                    'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/originals/noremove.png',
                  file: 'https://teemutestaa.fi/wp-content/uploads/wpcloud-updater/images/1000035463329546260/diffs/update-steps/diffs/after_imagify_update/noremove.png',
                },
              ],
            },
          ],
        },
      },
    },
  ],
};

// DETAILS/WPONE/VULNERABILITIES

// 10 core vulns (1 none - 2 low - 3 medium - 2 high - 2 crit)
const DUMMY_CORE_DATA: UpdateVulnerabilityRow[] = [
  {
    id: 0,
    cvssScore: 0,
    vulnName: 'Vuln 0',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.CORE,
    pluginName: 'WordPress Core',
    pluginDisplayName: 'Wordpress Core',
    currentVer: '6.4.3',
    updateVer: null,
  },
  {
    id: 1,
    cvssScore: 0.1,
    vulnName: 'Vuln 1',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.CORE,
    pluginName: 'WordPress Core',
    pluginDisplayName: 'Wordpress Core',
    currentVer: '6.4.3',
    updateVer: '7.3.1',
  },
  {
    id: 2,
    cvssScore: 2.2,
    vulnName: 'Vuln 2',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.CORE,
    pluginName: 'WordPress Core',
    pluginDisplayName: 'Wordpress Core',
    currentVer: '6.4.3',
    updateVer: '7.3.3',
  },
  {
    id: 3,
    cvssScore: 4.2,
    vulnName: 'Vuln 3',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.CORE,
    pluginName: 'WordPress Core',
    pluginDisplayName: 'Wordpress Core',
    currentVer: '6.4.3',
    updateVer: '7.3.2',
  },
  {
    id: 4,
    cvssScore: 4.2,
    vulnName: 'Vuln 4',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.CORE,
    pluginName: 'WordPress Core',
    pluginDisplayName: 'Wordpress Core',
    currentVer: '6.4.3',
    updateVer: null,
  },
  {
    id: 5,
    cvssScore: 6.2,
    vulnName: 'Vuln 5',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.CORE,
    pluginName: 'WordPress Core',
    pluginDisplayName: 'Wordpress Core',
    currentVer: '6.4.3',
    updateVer: null,
  },
  {
    id: 6,
    cvssScore: 7.2,
    vulnName: 'Vuln 6',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.CORE,
    pluginName: 'WordPress Core',
    pluginDisplayName: 'Wordpress Core',
    currentVer: '6.4.3',
    updateVer: null,
  },
  {
    id: 7,
    cvssScore: 7.3,
    vulnName: 'Vuln 7',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.CORE,
    pluginName: 'WordPress Core',
    pluginDisplayName: 'Wordpress Core',
    currentVer: '6.4.3',
    updateVer: '6.4.4',
  },
  {
    id: 8,
    cvssScore: 9.2,
    vulnName: 'Vuln 8',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.CORE,
    pluginName: 'WordPress Core',
    pluginDisplayName: 'Wordpress Core',
    currentVer: '6.4.3',
    updateVer: null,
  },
  {
    id: 9,
    cvssScore: 9.3,
    vulnName: 'Vuln 9',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.CORE,
    pluginName: 'WordPress Core',
    pluginDisplayName: 'Wordpress Core',
    currentVer: '6.4.3',
    updateVer: '6.5.7',
  },
];

// 10 plugins vulns (0 none - 0 low - 4 medium - 4 high - 2 crit)
const DUMMY_PLUGINS_DATA: UpdateVulnerabilityRow[] = [
  {
    id: 10,
    cvssScore: 4,
    vulnName: 'Vuln 0',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.PLUGINS,
    pluginName: 'plugin-1',
    pluginDisplayName: 'Plugin 1',
    currentVer: '6.4.3',
    updateVer: null,
  },
  {
    id: 11,
    cvssScore: 4.1,
    vulnName: 'Vuln 1',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.PLUGINS,
    pluginName: 'plugin-1',
    pluginDisplayName: 'Plugin 1',
    currentVer: '6.4.3',
    updateVer: '7.3.1',
  },
  {
    id: 12,
    cvssScore: 4.2,
    vulnName: 'Vuln 2',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.PLUGINS,
    pluginName: 'plugin-2',
    pluginDisplayName: 'Plugin 2',
    currentVer: '6.4.3',
    updateVer: null,
  },
  {
    id: 13,
    cvssScore: 6.2,
    vulnName: 'Vuln 3',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.PLUGINS,
    pluginName: 'plugin-3',
    pluginDisplayName: 'Plugin 3',
    currentVer: '6.4.3',
    updateVer: '7.3.1',
  },
  {
    id: 14,
    cvssScore: 7.2,
    vulnName: 'Vuln 4',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.PLUGINS,
    pluginName: 'plugin-3',
    pluginDisplayName: 'Plugin 3',
    currentVer: '6.4.3',
    updateVer: '7.3.5',
  },
  {
    id: 15,
    cvssScore: 8.2,
    vulnName: 'Vuln 5',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.PLUGINS,
    pluginName: 'plugin-4',
    pluginDisplayName: 'Plugin 4',
    currentVer: '6.4.3',
    updateVer: '8.3.1',
  },
  {
    id: 16,
    cvssScore: null,
    vulnName: 'Vuln 6',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.PLUGINS,
    pluginName: 'plugin-5',
    pluginDisplayName: 'Plugin 5',
    currentVer: '6.4.3',
    updateVer: '8.4.2',
  },
  {
    id: 17,
    cvssScore: null,
    vulnName: 'Vuln 7',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.PLUGINS,
    pluginName: 'plugin-6',
    pluginDisplayName: 'Plugin 6',
    currentVer: '6.4.3',
    updateVer: null,
  },
  {
    id: 18,
    cvssScore: 9.4,
    vulnName: 'Vuln 8',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.PLUGINS,
    pluginName: 'plugin-6',
    pluginDisplayName: 'Plugin 6',
    currentVer: '6.4.3',
    updateVer: null,
  },
  {
    id: 19,
    cvssScore: 9.5,
    vulnName: 'Vuln 9',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.PLUGINS,
    pluginName: 'plugin-7',
    pluginDisplayName: 'Plugin 7',
    currentVer: '6.4.3',
    updateVer: '7.3.1',
  },
];

// 9 themes vulns (3 none - 2 low - 0 medium - 0 high - 4 crit)
const DUMMY_THEMES_DATA: UpdateVulnerabilityRow[] = [
  {
    id: 20,
    cvssScore: 0,
    vulnName: 'Vuln 0',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.THEMES,
    pluginName: 'theme-1',
    pluginDisplayName: 'Theme 1',
    currentVer: '6.4.6',
    updateVer: null,
  },
  {
    id: 21,
    cvssScore: 0,
    vulnName: 'Vuln 1',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.THEMES,
    pluginName: 'theme-2',
    pluginDisplayName: 'Theme 2',
    currentVer: '6.4.6',
    updateVer: null,
  },
  {
    id: 22,
    cvssScore: 0,
    vulnName: 'Vuln 2',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.THEMES,
    pluginName: 'theme-2',
    pluginDisplayName: 'Theme 2',
    currentVer: '6.4.6',
    updateVer: null,
  },
  {
    id: 23,
    cvssScore: 3.2,
    vulnName: 'Vuln 3',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.THEMES,
    pluginName: 'theme-3',
    pluginDisplayName: 'Theme 3',
    currentVer: '6.4.6',
    updateVer: null,
  },
  {
    id: 24,
    cvssScore: 3.3,
    vulnName: 'Vuln 4',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.THEMES,
    pluginName: 'theme-4',
    pluginDisplayName: 'Theme 4',
    currentVer: '6.4.6',
    updateVer: null,
  },
  {
    id: 25,
    cvssScore: 9.2,
    vulnName: 'Vuln 5',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.THEMES,
    pluginName: 'theme-4',
    pluginDisplayName: 'Theme 4',
    currentVer: '6.4.6',
    updateVer: '7.3.1',
  },
  {
    id: 26,
    cvssScore: 9.2,
    vulnName: 'Vuln 6',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.THEMES,
    pluginName: 'theme-4',
    pluginDisplayName: 'Theme 4',
    currentVer: '6.4.6',
    updateVer: '8.3.1',
  },
  {
    id: 27,
    cvssScore: null,
    vulnName: 'Vuln 7',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.THEMES,
    pluginName: 'theme-5',
    pluginDisplayName: 'Theme 5',
    currentVer: '6.4.6',
    updateVer: '8.2.1',
  },
  {
    id: 28,
    cvssScore: 10,
    vulnName: 'Vuln 8',
    vulnUrl: 'https://google.fi',
    type: UpdateVulnerabilityType.THEMES,
    pluginName: 'theme-5',
    pluginDisplayName: 'Theme 5',
    currentVer: '6.4.6',
    updateVer: null,
  },
];

export const DUMMY_TABLE_DATA: UpdateVulnerabilityRow[] = [
  ...DUMMY_CORE_DATA,
  ...DUMMY_PLUGINS_DATA,
  ...DUMMY_THEMES_DATA,
];

// ----------------------------------------------------------------------
// DETAILS/WPONE/BACKUPS

const DUMMY_FILES_BACKUPS: number[] = [
  Math.floor(new Date().getTime() / 1000) - 24 * 60 * 60 * 14, // 14 days ago
  Math.floor(new Date().getTime() / 1000) - 24 * 60 * 60 * 13, // 13 days ago
  Math.floor(new Date().getTime() / 1000) - 24 * 60 * 60 * 12, // 12 days ago
  Math.floor(new Date().getTime() / 1000) - 24 * 60 * 60 * 11, // 11 days ago
  Math.floor(new Date().getTime() / 1000) - 24 * 60 * 60 * 10, // 10 days ago
  Math.floor(new Date().getTime() / 1000) - 24 * 60 * 60 * 9, // 9 days ago
  Math.floor(new Date().getTime() / 1000) - 24 * 60 * 60 * 8, // 8 days ago
  Math.floor(new Date().getTime() / 1000), // Today
];

const DUMMY_STAGING_DATABASE_BACKUPS: { timestamp: number; fileName: string }[] = [];

const DUMMY_PRODUCTION_DATABASE_BACKUPS: { timestamp: number; fileName: string }[] = [
  {
    timestamp: Math.floor(new Date().getTime() / 1000) - 24 * 60 * 60 * 14, // 14 days ago
    fileName: 'prod_14days.txt',
  },
  {
    timestamp: Math.floor(new Date().getTime() / 1000) - 24 * 60 * 60 * 13, // 13 days ago
    fileName: 'prod_13days.txt',
  },
  {
    timestamp: Math.floor(new Date().getTime() / 1000) - 24 * 60 * 60 * 12, // 12 days ago
    fileName: 'prod_12days.txt',
  },
  {
    timestamp: Math.floor(new Date().getTime() / 1000) - 24 * 60 * 60 * 3, // 3 days ago
    fileName: 'prod_3days.txt',
  },
  {
    timestamp: Math.floor(new Date().getTime() / 1000) - 24 * 60 * 60 * 2, // 2 days ago
    fileName: 'prod_2days.txt',
  },
  {
    timestamp: Math.floor(new Date().getTime() / 1000) - 24 * 60 * 60, // 1 days ago
    fileName: 'prod_1days.txt',
  },
  {
    timestamp: Math.floor(new Date().getTime() / 1000) - 2 * 60 * 60, // Today - 2 hours
    fileName: 'prod_today_2hours.txt',
  },
  {
    timestamp: Math.floor(new Date().getTime() / 1000) - 60 * 60, // Today - 1 hour
    fileName: 'prod_today_1hour.txt',
  },
  {
    timestamp: Math.floor(new Date().getTime() / 1000), // Today
    fileName: 'prod_today.txt',
  },
];

export const DUMMY_BACKUPS_DATA: GetBackupTimestampsResponseDTO = {
  fileItems: DUMMY_FILES_BACKUPS,
  stagingDatabaseItems: DUMMY_STAGING_DATABASE_BACKUPS,
  productionDatabaseItems: DUMMY_PRODUCTION_DATABASE_BACKUPS,
  counts: 11,
};

// ----------------------------------------------------------------------
// DETAILS/MWP/OVERVIEW

export const DUMMY_EVENT_CONTENT: CommunityCardContent[] = [
  {
    type: CommunityCardContentType.event,
    location: 'Torino, Italy',
    title: 'WordCamp Europe',
    subtitle: '13-15 June, 2024. Torino, Italy',
    description:
      'Protect your account with two-step verification, receive uptime notifications on your phone and more.',
    ctaBtn: { text: 'See event details', onClick: () => console.log('WordCamp Europe') },
  },
  {
    type: CommunityCardContentType.news,
    title: 'How WordPress is creating a faster web',
    subtitle: 'Author/Source',
    description:
      'WordCamp Europe is a big event where people who love WordPress gather to learn and share ideas; the link will take you to an external website of the event.',
    ctaBtn: {
      text: 'Read more',
      onClick: () => console.log('How WordPress is creating a faster web'),
    },
  },
];

// DETAILS/MWP/STATUS

export const DUMMY_STATUS_ISSUE: MWPSiteStatusDetails[] = [
  // Site incomplete
  {
    status: MWPSiteStatusEnum.INCOMPLETE,
  },
  // Site up
  {
    status: MWPSiteStatusEnum.UP,
  },
  {
    status: MWPSiteStatusEnum.UP,
    issue: MWPSiteIssueEnum.SSL,
  },
  {
    status: MWPSiteStatusEnum.UP,
    issue: MWPSiteIssueEnum.REDIRECTION,
  },
  // Site down
  {
    status: MWPSiteStatusEnum.DOWN,
    issue: MWPSiteIssueEnum.BROKEN_WP_CORE,
  },
  {
    status: MWPSiteStatusEnum.DOWN,
    issue: MWPSiteIssueEnum.REST_API,
  },
  {
    status: MWPSiteStatusEnum.DOWN,
    issue: MWPSiteIssueEnum.GENERAL,
  },
  {
    status: MWPSiteStatusEnum.DOWN,
    issue: MWPSiteIssueEnum.FORBIDDEN,
  },
  {
    status: MWPSiteStatusEnum.DOWN,
    issue: MWPSiteIssueEnum.NOT_FOUND,
  },
  {
    status: MWPSiteStatusEnum.DOWN,
    issue: MWPSiteIssueEnum.INTERNAL_ERROR,
  },
  {
    status: MWPSiteStatusEnum.DOWN,
    issue: MWPSiteIssueEnum.SERVICE_UNAVAILABLE,
  },
  // Site migrating
  {
    status: MWPSiteStatusEnum.MIGRATING,
  },
  // Site suspended
  {
    status: MWPSiteStatusEnum.SUSPENDED,
    issue: MWPSiteIssueEnum.HACKED,
  },
  {
    status: MWPSiteStatusEnum.SUSPENDED,
    issue: MWPSiteIssueEnum.INVOICE,
  },
];

export const DUMMY_PREVIEW_CONTENT: PreviewCardContent[] = [
  {
    title: 'Mock site content 1',
    siteStatus: {
      status: MWPSiteStatusEnum.UP,
    },
    updateStatus: MWPUpdateStatusEnum.success,
    lastUpdate: new Date(),
    nextUpdate: new Date(),
    imageUrl: 'https://picsum.photos/seed/picsum/536/354',
  },
  {
    title: 'Mock site content 2',
    siteStatus: {
      status: MWPSiteStatusEnum.UP,
    },
    updateStatus: MWPUpdateStatusEnum.failed,
    lastUpdate: new Date(),
    nextUpdate: new Date(),
    imageUrl: 'https://picsum.photos/seed/picsum/536/354',
  },
  {
    title: 'Mock site content 3',
    siteStatus: {
      status: MWPSiteStatusEnum.UP,
    },
    updateStatus: MWPUpdateStatusEnum.partialFailed,
    lastUpdate: new Date(),
    nextUpdate: new Date(),
    imageUrl: 'https://picsum.photos/seed/picsum/536/354',
  },
  {
    title: 'Mock site content 4',
    siteStatus: {
      status: MWPSiteStatusEnum.DOWN,
      issue: MWPSiteIssueEnum.BROKEN_WP_CORE,
    },
    updateStatus: MWPUpdateStatusEnum.partialFailed,
    lastUpdate: new Date(),
    nextUpdate: new Date(),
    imageUrl: 'https://picsum.photos/seed/picsum/536/354',
  },
  {
    title: 'Mock site content 5',
    siteStatus: {
      status: MWPSiteStatusEnum.UP,
    },
    updateStatus: MWPUpdateStatusEnum.noUpdate,
    lastUpdate: new Date(),
    nextUpdate: new Date(),
    imageUrl: 'https://picsum.photos/seed/picsum/536/354',
  },
  {
    title: 'Mock site content 6',
    siteStatus: {
      status: MWPSiteStatusEnum.UP,
    },
    updateStatus: MWPUpdateStatusEnum.updateDeactivated,
    lastUpdate: new Date(),
    nextUpdate: new Date(),
    imageUrl: 'https://picsum.photos/seed/picsum/536/354',
  },
  {
    title: 'Mock site content 7',
    siteStatus: {
      status: MWPSiteStatusEnum.UP,
    },
    updateStatus: MWPUpdateStatusEnum.updateDeactivated,
    lastUpdate: new Date(),
    nextUpdate: new Date(),
    imageUrl: '',
  },
];
