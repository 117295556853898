import { parseDomain, ParseResultType } from 'parse-domain';
// @types
import {
  LighthousePerformanceSubScoreEnum,
  LighthouseScoreEnum,
  LighthouseStatusEnum,
  SitePlanEnum,
} from 'src/@types/site';
import { UserResourceLimitTypeEnum } from 'src/@types/user';
import { ResourceLimitPlanEnum } from 'src/@types/plan';
import { TTL } from 'src/@types/domain';

// ----------------------------------------------------------------------

// CONVERT CONNECTIONT TYPE
export const switchPortValues = (type: string) => {
  switch (type) {
    case 'SSH':
      return 22;
    case 'SFTP':
      return 22;
    case 'FTP':
      return 21;
    case 'FTPS':
      return 990;
    default:
      return 1;
  }
};

// ----------------------------------------------------------------------

// CONVERT STATUS CODE
export const convertStatusCode = (code: number) => {
  switch (code) {
    case 200:
      return 'wpone.general.requestResponse.successMessage';
    case 403:
      return 'wpone.general.requestResponse.errorMessage.403';
    case 404:
      return 'wpone.general.requestResponse.errorMessage.404';
    case 500:
      return 'wpone.general.requestResponse.errorMessage.500';
    default:
      return 'wpone.general.requestResponse.errorMessage.other';
  }
};

// ----------------------------------------------------------------------

// CONVERT BETWEEN NUMBER AND TTL
export function convertNumberToTtl(ttl: number) {
  const ttlArr = [1, 3600, 7200, 14400, 18000, 57600, 86400];

  let closestTtl = ttlArr.reduce(function (prev, cur) {
    return Math.abs(cur - ttl) < Math.abs(prev - ttl) ? cur : prev;
  });

  switch (closestTtl) {
    case 3600:
      return TTL.oneHour;
    case 7200:
      return TTL.twoHours;
    case 14400:
      return TTL.fourHours;
    case 18000:
      return TTL.fiveHours;
    case 57600:
      return TTL.sixteenHours;
    case 86400:
      return TTL.oneDay;
    default:
      return TTL.auto;
  }
}

export function convertTtlToNumber(ttl: TTL, isBunnyDns: boolean) {
  switch (ttl) {
    case TTL.oneHour:
      return 3600;
    case TTL.twoHours:
      return 7200;
    case TTL.fourHours:
      return 14400;
    case TTL.fiveHours:
      return 18000;
    case TTL.sixteenHours:
      return 57600;
    case TTL.oneDay:
      return 86400;
    default:
      return isBunnyDns ? 300 : 1;
  }
}

// ----------------------------------------------------------------------

//CONVERT LANGUAGE
export function convertCodeToLanguage(code: string) {
  switch (code) {
    case 'en':
      return 'English';
    case 'fi':
      return 'Finnish';
    case 'nl':
      return 'Dutch';
    default:
      return 'English';
  }
}

export function convertLanguageToCode(code: string) {
  switch (code) {
    case 'English':
      return 'en';
    case 'Finnish':
      return 'fi';
    case 'Dutch':
      return 'nl';
    default:
      return 'en';
  }
}

// ----------------------------------------------------------------------

// CONVERT DISK and DATABASE USAGE TO STATUS
export function convertDiskAndDatabaseUsageToStatus(usage: number, limit: number) {
  const usageRatio = usage / limit;
  if (usageRatio >= 1) {
    return 'error';
  } else if (usageRatio >= 0.8) {
    return 'warning';
  } else {
    return 'good';
  }
}

// ----------------------------------------------------------------------

// CONVERT DOMAIN TO ROOT DOMAIN ONLY
export const parseDomainHandler = (hostname: string) => {
  const parseResult = parseDomain(convertToIDN(hostname));

  if (parseResult.type === ParseResultType.Listed) {
    const { domain, topLevelDomains } = parseResult;

    return domain?.concat(`.${topLevelDomains.join('.')}`) !== undefined
      ? convertToUnicode(domain.concat(`.${topLevelDomains.join('.')}`))
      : undefined;
  } else {
    return undefined;
  }
};

// ----------------------------------------------------------------------

// CONVERT DOMAIN TO UNICODE
export const convertToUnicode = (domain: string) => {
  const punycode = require('punycode/');
  return punycode.toUnicode(domain);
};

// CONVERT DOMAIN TO IDN
export const convertToIDN = (domain: string | undefined) => {
  const punycode = require('punycode/');
  if (!domain) {
    return '';
  }
  if (/[^a-zA-Z0-9.-]/.test(domain)) {
    const punycodeDomain = punycode.toASCII(domain);
    return punycodeDomain;
  } else {
    return domain;
  }
};

// ----------------------------------------------------------------------

const getFormatLighthouseValue = (value: number, isPercentage: boolean, isMs: boolean): number => {
  if (isPercentage) return Math.round(value * 100);
  if (isMs) return Math.round(value);
  return Math.round(value * 100) / 100;
};

const getFormatLighthouseValueDisplay = (
  value: number,
  isPercentage: boolean,
  isMs: boolean
): string => {
  if (isPercentage) return `${value}%`;
  if (isMs) return `${value}ms`;
  return `${value}`;
};

const getLighthouseValueDiff = (
  currentValue: number,
  previousValue: number,
  isPercentage: boolean,
  isMs: boolean
): number => {
  // Calculate current value and current value result
  let valueDiff = currentValue - previousValue;
  if (!isPercentage && !isMs) {
    valueDiff = Number(valueDiff.toFixed(2));
  }
  return valueDiff;
};

const getLighthouseValueDiffDisplay = (
  valueDiff: number,
  isBetter: boolean,
  isPercentageDisplayed: boolean,
  isMsDisplayed: boolean
): string => {
  if (valueDiff === 0) {
    return isPercentageDisplayed ? '0%' : isMsDisplayed ? '0ms' : '0';
  }

  if (isBetter) {
    if (isPercentageDisplayed) return `+${valueDiff}%`;
    if (isMsDisplayed) return valueDiff < -10 ? `${valueDiff}ms` : '';
    return `+${valueDiff}`;
  } else {
    if (isPercentageDisplayed) return `${valueDiff}%`;
    if (isMsDisplayed) return valueDiff > 10 ? `+${valueDiff}ms` : '';
    return `${valueDiff}`;
  }
};

// CONVERT PREVIOUS AND CURRENT SITE GOOGLE LIGHTHOUSE STATS FOR COMPARISON AND DISPLAY
export const convertLighthouseStatsDisplay = (
  lighthouseStat: LighthouseScoreEnum | LighthousePerformanceSubScoreEnum,
  previousValue: number | undefined,
  currentValue: number | undefined
): {
  convertedCurrentValue: number | undefined;
  convertedCurrentValueDisplay: string | undefined;
  valueDiffDisplay: string | undefined;
  isBetter: boolean | undefined;
  isEqual: boolean | undefined;
} => {
  // Lighthouse score displayed as percentage
  const isPercentageDisplayed = (
    [
      LighthouseScoreEnum.PERFORMANCE,
      LighthouseScoreEnum.ACCESSIBILITY,
      LighthouseScoreEnum.SEO,
      LighthouseScoreEnum.BEST_PRACTICES,
    ] as (LighthouseScoreEnum | LighthousePerformanceSubScoreEnum)[]
  ).includes(lighthouseStat);

  // Lighthouse performance sub-score displayed as ms (except CLS, which is displayed in only plain number)
  const isMsDisplayed = (
    [
      LighthousePerformanceSubScoreEnum.FCP,
      LighthousePerformanceSubScoreEnum.SPEED_INDEX,
      LighthousePerformanceSubScoreEnum.LCP,
      LighthousePerformanceSubScoreEnum.BLOCKING,
    ] as (LighthouseScoreEnum | LighthousePerformanceSubScoreEnum)[]
  ).includes(lighthouseStat);

  // If no data about current value
  if (currentValue === undefined) {
    return {
      convertedCurrentValue: undefined,
      convertedCurrentValueDisplay: undefined,
      valueDiffDisplay: undefined,
      isBetter: undefined,
      isEqual: undefined,
    };
  }

  // Calculate current value and current value result
  const convertedCurrentValue = getFormatLighthouseValue(
    currentValue,
    isPercentageDisplayed,
    isMsDisplayed
  );
  const convertedCurrentValueDisplay = getFormatLighthouseValueDisplay(
    convertedCurrentValue,
    isPercentageDisplayed,
    isMsDisplayed
  );

  // If no data about previous value
  if (previousValue === undefined) {
    return {
      convertedCurrentValue,
      convertedCurrentValueDisplay,
      valueDiffDisplay: undefined,
      isBetter: undefined,
      isEqual: undefined,
    };
  }

  // Calculate previous value
  const convertedPreviousValue = getFormatLighthouseValue(
    previousValue,
    isPercentageDisplayed,
    isMsDisplayed
  );

  // Calculate value diff (different in case of CLS) and determine if the new value is better than the old one
  const valueDiff = getLighthouseValueDiff(
    convertedCurrentValue,
    convertedPreviousValue,
    isPercentageDisplayed,
    isMsDisplayed
  );
  const isBetter = !isMsDisplayed ? valueDiff > 0 : valueDiff < 0;

  return {
    convertedCurrentValue,
    convertedCurrentValueDisplay,
    valueDiffDisplay: getLighthouseValueDiffDisplay(
      valueDiff,
      isBetter,
      isPercentageDisplayed,
      isMsDisplayed
    ),
    isBetter,
    isEqual: valueDiff === 0,
  };
};

// CONVERT LIGHTHOUSE STATUS AND ICON
export const convertLighthouseStatusToColorCode = (
  status: LighthouseStatusEnum
): {
  iconName: string;
  color: string;
} => {
  switch (status) {
    case LighthouseStatusEnum.unknown:
      return {
        iconName: 'remove',
        color: '--color-body-on-default',
      };
    case LighthouseStatusEnum.alert:
      return {
        iconName: 'dangerous',
        color: '--color-signal-alert',
      };
    case LighthouseStatusEnum.warning:
      return {
        iconName: 'warning',
        color: '--color-signal-warning',
      };
    default:
      return {
        iconName: 'check_circle',
        color: '--color-signal-success',
      };
  }
};

// CONVERT LIGHTHOUSE VITALS VALUE TO COLOR CODE
export function convertLighthouseValueToStatus(
  lighthouseStat: LighthouseScoreEnum | LighthousePerformanceSubScoreEnum,
  value: number | undefined
) {
  // Lighthouse score displayed as percentage
  const isPercentageDisplayed = (
    [
      LighthouseScoreEnum.PERFORMANCE,
      LighthouseScoreEnum.ACCESSIBILITY,
      LighthouseScoreEnum.SEO,
      LighthouseScoreEnum.BEST_PRACTICES,
    ] as (LighthouseScoreEnum | LighthousePerformanceSubScoreEnum)[]
  ).includes(lighthouseStat);

  const performanceSubScoreThresholds = {
    [LighthousePerformanceSubScoreEnum.FCP]: { warning: 1800, poor: 3000 },
    [LighthousePerformanceSubScoreEnum.SPEED_INDEX]: { warning: 3400, poor: 5800 },
    [LighthousePerformanceSubScoreEnum.LCP]: { warning: 2500, poor: 4000 },
    [LighthousePerformanceSubScoreEnum.BLOCKING]: { warning: 200, poor: 600 },
    [LighthousePerformanceSubScoreEnum.CLS]: { warning: 0.1, poor: 0.25 },
  };

  let status = LighthouseStatusEnum.unknown;

  if (value !== undefined) {
    const thresholds = isPercentageDisplayed
      ? { warning: 89, poor: 49 }
      : performanceSubScoreThresholds[lighthouseStat as LighthousePerformanceSubScoreEnum];
    if (isPercentageDisplayed) {
      status =
        value > thresholds.warning
          ? LighthouseStatusEnum.good
          : value > thresholds.poor
          ? LighthouseStatusEnum.warning
          : LighthouseStatusEnum.alert;
    } else {
      status =
        value < thresholds.warning
          ? LighthouseStatusEnum.good
          : value < thresholds.poor
          ? LighthouseStatusEnum.warning
          : LighthouseStatusEnum.alert;
    }
  }

  return status;
}

// ----------------------------------------------------------------------

// CONVERT RESOURCES LIMIT AND PLAN
export const convertResourcesLimit = (sitesLimit: number, zonesLimit: number) => {
  if (sitesLimit === 0 && zonesLimit === 0) {
    return ResourceLimitPlanEnum.free;
  } else if (sitesLimit === 100 && zonesLimit === 100) {
    return ResourceLimitPlanEnum.default;
  } else {
    return ResourceLimitPlanEnum.custom;
  }
};

export const convertResourcesLimitPlan = (plan: ResourceLimitPlanEnum) => {
  if (plan === ResourceLimitPlanEnum.free) {
    return [
      { limitType: UserResourceLimitTypeEnum.sites, limitValue: 0 },
      { limitType: UserResourceLimitTypeEnum.zones, limitValue: 0 },
    ];
  } else if (plan === ResourceLimitPlanEnum.default) {
    return [
      { limitType: UserResourceLimitTypeEnum.sites, limitValue: 100 },
      { limitType: UserResourceLimitTypeEnum.zones, limitValue: 100 },
    ];
  } else {
    return [];
  }
};

// ----------------------------------------------------------------------

// CONVERT SITE PLAN TO MAX DATABASE SIZE
export function convertPlanToMaxDbSize(planName: SitePlanEnum): number {
  switch (planName) {
    case SitePlanEnum.starter:
      return 1;
    case SitePlanEnum.business:
      return 2;
    case SitePlanEnum.businessPremium:
      return 3;
    case SitePlanEnum.enterprise:
      return 4;
    case SitePlanEnum.custom:
    case SitePlanEnum.internal:
      return Number.MAX_VALUE;
    default:
      console.warn('Unknown plan name:', planName);
      return 0;
  }
}

// ----------------------------------------------------------------------

// Temporary function for converting old plans to new plans in staging
// Check if the input string is a valid enum value of no then return custom
export function convertOldSitePlan(inputString: string): SitePlanEnum {
  if (Object.values(SitePlanEnum).includes(inputString as SitePlanEnum)) {
    return inputString as SitePlanEnum;
  } else {
    return SitePlanEnum.custom;
  }
}

// ----------------------------------------------------------------------
// Temporary function for converting old sortBy string to sortBy string in getsites query
// Because the enum type value SiteColumnsEnum from get current user response is different from the new one
export function convertOldSiteOrderBy(input: string): string {
  const transformations: { [key: string]: string } = {
    diskusage: 'disk_usage',
    dbsize: 'database_size',
    php: 'php_version',
  };

  return transformations[input] || input;
}
