// ----------------------------------------------------------------------
// BOTH

import { UpdateDetails } from '@joonasvanhatapio/wp-cloud-backend-types';

// SITE TABLE
export type SiteState = {
  // Sites table customization
  selectedCols: SiteColumn[];
  unselectedCol: SiteColumn[];

  // Created sites => Display created site banner
  createdSites: string[];

  // Sites with disk usage upgraded => Display successfully/fail banner
  diskUpgradedSites: { siteName: string; fromTab: string; isSuccess: boolean }[];

  // Sites with plan changed => Display upgraded/downgraded successfully/fail banner
  planChangedSites: {
    siteName: string;
    fromTab: string;
    isUpgraded: boolean; // Upgraded/Downgraded
    isSuccess: boolean;
  }[];

  // Vulnerabilities that are currently being updated
  updatingVulnsInfo: { siteName: string; updatingVulns: UpdateVulnerabilityRow[] }[];

  // MWP site performance sub score description
  showSubScoreDescription: boolean;
};

export enum InstallationProvider {
  WP_ONE = 'WP.one',
  ONE_HOP = 'onehop',
}

export enum UptimeStatusEnum {
  up = 'up',
  down = 'down',
  unknown = 'unknown',
}

export enum SiteClusterEnum {
  WP_TEST = 'wp-test',
  ONE_HOP = 'onehop',
  NORTH1_A_1 = 'wp-cloud-europe-north1-a-1',
  NORTH1_A_2 = 'wp-cloud-europe-north1-a-2',
  NORTH1_A_3 = 'wp-cloud-europe-north1-a-3',
  NORTH1_A_4 = 'wp-cloud-europe-north1-a-4',
  NORTH1_A_5 = 'wp-cloud-europe-north1-a-5',
  NORTH1_A_6 = 'wp-cloud-europe-north1-a-6',
  NORTH1_A_7 = 'wp-cloud-europe-north1-a-7',
  NORTH1_A_8 = 'wp-cloud-europe-north1-a-8',
  NORTH1_A_9 = 'wp-cloud-europe-north1-a-9',
  NORTH1_A_10 = 'wp-cloud-europe-north1-a-10',
  WEST4_A_1 = 'wp-cloud-europe-west4-a-1',
  WEST6_A_1 = 'wp-cloud-europe-west6-a-1',
}

export enum SiteColumnsEnum {
  location = 'location',
  cluster = 'cluster',
  php = 'php',
  additionalDomains = 'additionalDomains',
  sshHost = 'sshHost',
  sshPort = 'sshPort',
  sshTerminalCommand = 'sshTerminalCommand',
  visitors = 'visitors',
  bandwidth = 'bandwidth',
  dbsize = 'dbsize',
  diskusage = 'diskusage',
  performance = 'performance',
  accessibility = 'accessibility',
  seo = 'seo',
  bestPractices = 'bestPractices',
  fcp = 'fcp',
  speedIndex = 'speedIndex',
  lcp = 'lcp',
  blocking = 'blocking',
  cls = 'cls',
  phpMyAdmin = 'phpMyAdmin',
  plan = 'plan',
}

export enum SiteInfoCategoryEnum {
  basic = 'basic',
  connectivity = 'connectivity',
  statistics = 'statistics',
  vitalsScore = 'vitalsScore',
  vitalsPerformanceMetrics = 'vitalsPerformanceMetrics',
  tools = 'tools',
}

export type SiteColumn = {
  id: SiteColumnsEnum;
  tooltip?: string;
  category: SiteInfoCategoryEnum;
  label?: string;
  customLabel: string;
  sort?: boolean;
};

// WPONE/VULNERABILITIES - MWP/SECURITY
export enum UpdateVulnerabilityType {
  CORE = 'core',
  PLUGINS = 'plugins',
  THEMES = 'themes',
}

export type UpdateVulnerabilityRow = {
  id: number;
  cvssScore: number | null;
  vulnName: string;
  vulnUrl: string;
  type: UpdateVulnerabilityType;
  pluginName: string;
  pluginDisplayName: string;
  currentVer: string;
  updateVer: string | null;
  exploitable?: boolean; // Only for MWP/SECURITY
};

// ----------------------------------------------------------------------
// WPONE

// SITE
export enum SiteStatusEnum {
  active = 'active',
  suspended = 'suspended',
}

// PLAN SELECTION
export enum SitePlanEnum {
  starter = 'starter',
  business = 'business',
  businessPremium = 'businessPremium',
  enterprise = 'enterprise',
  custom = 'custom',

  //
  internal = 'internal',
}

export enum SiteBillingEnum {
  monthly = 'monthly',
  yearly = 'yearly',

  //
  none = 'none',
}

export type PlanSelectionState = {
  create: {
    isCreating: boolean;
    isMigrating: boolean; // Whether this site going to be migrated after creation
  };
  upgrade: {
    upgradingSite: {
      currentPlan: PlanDetails;
      currentDiskSpace: number; // Disk space over plan limit space will prevent downgrading plan
      fromTab: string; // From which site tab the plan selection page was redirected
    } | null;
  };
};

export type PlanDetails = {
  name: SitePlanEnum;
  billing: SiteBillingEnum;
};

export type PlanCardDetails = {
  name: SitePlanEnum;
  description: string;
  price: string; // Per month
  features: {
    installation: number;
    cpu: number;
    storage: number;
    ram: number;
    phpWorkers: number;
    dbStorage: number;
  };
};

export type PlanOverview = {
  features: {
    domainRegisRenew: {
      starter: boolean;
      business: boolean;
      businessPremium: boolean;
      enterprise: boolean;
    };
    emailBox: {
      starter: number;
      business: number;
      businessPremium: number;
      enterprise: number;
    };
    emailQuota: {
      starter: number;
      business: number;
      businessPremium: number;
      enterprise: number;
    };
    ssl: {
      starter: boolean;
      business: boolean;
      businessPremium: boolean;
      enterprise: boolean;
    };
    wpRocket: {
      starter: boolean;
      business: boolean;
      businessPremium: boolean;
      enterprise: boolean;
    };
    imagify: {
      starter: boolean;
      business: boolean;
      businessPremium: boolean;
      enterprise: boolean;
    };
    uptimeMonitor: {
      starter: boolean;
      business: boolean;
      businessPremium: boolean;
      enterprise: boolean;
    };
    visualCheckAutoUpdate: {
      starter: boolean;
      business: boolean;
      businessPremium: boolean;
      enterprise: boolean;
    };
    bunnyCdn: {
      starter: boolean;
      business: boolean;
      businessPremium: boolean;
      enterprise: boolean;
    };
    webAppFirewall: {
      starter: boolean;
      business: boolean;
      businessPremium: boolean;
      enterprise: boolean;
    };
    ddosProtection: {
      starter: boolean;
      business: boolean;
      businessPremium: boolean;
      enterprise: boolean;
    };
  };
  serverInfrastructure: {
    googleComputeEngine: {
      starter: boolean;
      business: boolean;
      businessPremium: boolean;
      enterprise: boolean;
    };
    googleKubernetesEngine: {
      starter: boolean;
      business: boolean;
      businessPremium: boolean;
      enterprise: boolean;
    };
    adjustableResourcesPerSite: {
      starter: boolean;
      business: boolean;
      businessPremium: boolean;
      enterprise: boolean;
    };
    cloudDiskPerSite: {
      starter: boolean;
      business: boolean;
      businessPremium: boolean;
      enterprise: boolean;
    };
    multiLayerCaching: {
      starter: boolean;
      business: boolean;
      businessPremium: boolean;
      enterprise: boolean;
    };
    nginx: {
      starter: boolean;
      business: boolean;
      businessPremium: boolean;
      enterprise: boolean;
    };
    php: {
      starter: boolean;
      business: boolean;
      businessPremium: boolean;
      enterprise: boolean;
    };
    http2: {
      starter: boolean;
      business: boolean;
      businessPremium: boolean;
      enterprise: boolean;
    };
    // http3: {
    //   starter: boolean;
    //   business: boolean;
    //   businessPremium: boolean;
    //   enterprise: boolean;
    // };
    googleCloudSql: {
      starter: boolean;
      business: boolean;
      businessPremium: boolean;
      enterprise: boolean;
    };
  };
};

// ADD/MIGRATE SITE
export enum ConnectionType {
  SSH_PW = 'ssh_pw',
  SSH_KEY = 'ssh_key',
  FTP = 'ftp',
  SFTP = 'sftp',
  FTPS = 'ftps',
  WORDPRESS_CREDENTIALS = 'wordpress_credentials',
}

export type MigrationFormsData = {
  destinationSiteName: string;
  destinationSiteCluster: SiteClusterEnum;
  destinationSiteNamespace: string;
  sourceSiteUrl: string;
  connectionType: ConnectionType;
  connectionHost: string;
  connectionPort: number;
  connectionUsername: string;
  //
  migrationIpAddress: string;
  publicSshKey: string;
};

export enum MigrationFormStepper {
  START = 'start',
  PREPARE_SITE = 'prepare_site',
  ADD_SSH_KEY = 'add_ssh_key',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}

export enum MigrationStatus {
  IN_PROGRESS = 'migration_in_progress',
  COMPLETED = 'migration_completed',
  FAILED = 'migration_failed',

  // Extra added status
  ABORTED = 'migration_aborted',
}

export enum MigrationStep {
  PREPARE = 'preparing_migration',
  DOWNLOAD = 'downloading_files_and_database',
  EXTRACT = 'extracting_files_and_database',
  FINALIZE = 'finalizing_and_cleaning_up',
}

export enum MigrationWPCredentialsStep {
  QUEUED = 'QUEUED',
  SETTING_UP = 'SETTING UP ENVIRONMENT',
  UPLOADING = 'UPLOADING',
  EXPORTING = 'EXPORTING',
  EXECUTING_SHELL = 'EXECUTING_SHELL',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export type MigrationSshKeyResponse = {
  error: string;
  message: string;
  data: {
    publicKey: string;
    sshClientIP: string;
  };
};

export type MigrationWPCredentialsResponse = {
  data: {
    id: string;
    domain: string;
    status: MigrationWPCredentialsStep;
    type: string;
    details?: {
      message: string;
    };
    created_at: Date;
    updated_at: Date;
  };
};

export type MigrationErrorResponse = {
  status: number;
  data: {
    message: string;
    statusCode: number;
    data: { input?: string; reason?: string; url?: string };
  };
};

export type MigrationDetails = {
  id?: string; // For WP credentials only
  //
  connectionType: ConnectionType;
  sourceSite: string;
  destinationSite: string;
  destinationSiteCluster: SiteClusterEnum;
  destinationSiteNamespace: string;
  startedAt: Date;
  endedAt: Date | null;
  currentStatus: MigrationStatus;
  failedMessage?: string;
};

export type MigrationWithWPCredentials = {
  ssh_configuration: {
    hostname: string;
    port: number;
    user: string;
    password: string;
  };
  domain: string;
  username: string;
  password: string;
};

export type MigrationState = {
  migratingAfterAddingSite: {
    hostname: string;
    cluster: SiteClusterEnum;
    namespace: string;
  } | null;
  //
  currentMigrationDetails: MigrationDetails | null;
  migrationsInProgress: MigrationDetails[];
};

// SETTINGS
export type PasswordInputState = {
  value: string;
  isHidden: boolean;
};

export enum PHPVersionEnum {
  'PHP74' = '7.4',
  'PHP80' = '8.0',
  'PHP81' = '8.1',
  'PHP82' = '8.2',
  'PHP83' = '8.3',
}

// ADDITIONAL DOMAIN
export type SiteDomain = {
  hostname: string;
  additionalHostnames: string[];
  error?: string;
};

export enum AdditionalDomainStatus {
  pointed = 'pointed',
  pending = 'pending',
  error = 'error',
}

// STATISTICS
export enum LighthouseScoreEnum {
  PERFORMANCE = 'performance',
  ACCESSIBILITY = 'accessibility',
  SEO = 'seo',
  BEST_PRACTICES = 'bestPractices',
}

export enum LighthousePerformanceSubScoreEnum {
  FCP = 'fcp',
  SPEED_INDEX = 'speedIndex',
  LCP = 'lcp',
  BLOCKING = 'blocking',
  CLS = 'cls',
}

export enum LighthouseStatusEnum {
  unknown = 'unknown',
  good = 'good',
  warning = 'warning',
  alert = 'alert',
}

export enum SitePlanDiskUsageEnum {
  'starter' = '10',
  'business' = '20',
  'businessPremium' = '40',
  'enterprise' = '80',
  'custom' = '',

  //
  'internal' = '',
}

// USER
export enum Role {
  developer = 'Developer',
  admin = 'Admin',
}

// BACKUPS
export enum RestoreMethodEnum {
  database = 'db',
  files = 'files',
  both = 'database_and_files',
}

export enum RestoreEnvironmentEnum {
  production = 'production',
  staging = 'staging',
}

export enum BackupTypeEnum {
  AUTOMATED = 'automated',
  MANUAL = 'manual',
}

export enum BackupStatus {
  IN_PROGRESS = 'backup_in_progress',
  COMPLETED = 'backup_completed',
  FAILED = 'backup_failed',

  // Extra added status
  ABORTED = 'backup_aborted',
}

export enum BackupStep {
  PREPARE = 'preparing',
  FETCH = 'fetching',
  RESTORE = 'restoring',
}

export type BackupTableRowProps = {
  date: Date;
  available: RestoreMethodEnum;
  type: BackupTypeEnum;
  // For DB and DB_AND_FILES backups
  env?: RestoreEnvironmentEnum;
  fileName?: string;
};

// Selected backup configuration for the restore process
export type BackupSettings = {
  cluster: string;
  date: Date;
  hostname: string;
  namespace: string;
  env: RestoreEnvironmentEnum;
  databaseBackupFileName?: string;
  method: RestoreMethodEnum;
};

export type BackupDetails = {
  id: number;
  settings: BackupSettings;
  notificationEmail: string;
  startedAt: Date;
  endedAt: Date | null;
  currentStatus: BackupStatus;
};

export type BackupState = {
  backupsInProgress: BackupDetails[];
};

// Temporary until Migration pages converted to Gravity (step component uses Gravity)
export type Step = MigrationWPCredentialsStep | MigrationStep | BackupStep;

export type StepProps = {
  steps: Step[];
  thisStep: Step;
  currentStep: Step | null;
};

// UPDATES
export enum UpdateDetailsTypeEnum {
  CORE = 'core',
  THEME = 'theme',
  PLUGIN = 'plugin',
}

export type UpdateReportStats = {
  wp: boolean | undefined; // Undefined: none - True: success - False: failed
  themes: {
    attempted: number;
    success: number;
  };
  plugins: {
    attempted: number;
    success: number;
  };
};

export type UpdateDetailsInfo = {
  type: UpdateDetailsTypeEnum;
  details: UpdateDetails;
};

// ----------------------------------------------------------------------
// MWP

// SITE
export enum MWPSiteStatusEnum {
  UP = 'up',
  DOWN = 'down',
  SUSPENDED = 'suspended',
  MIGRATING = 'migrating',
  INCOMPLETE = 'incomplete',
}

export enum MWPSiteIssueEnum {
  // Status up
  SSL = 'ssl',
  REDIRECTION = 'redirection',
  // Status down
  BROKEN_WP_CORE = 'broken_wp_core',
  REST_API = 'rest_api',
  GENERAL = 'general',
  FORBIDDEN = 'forbidden',
  NOT_FOUND = 'not_found',
  INTERNAL_ERROR = 'internal_error',
  SERVICE_UNAVAILABLE = 'service_unavailable',
  // Status suspended
  HACKED = 'hacked',
  INVOICE = 'invoice',
}

export type MWPSiteStatusDetails = {
  status: MWPSiteStatusEnum;
  issue?: MWPSiteIssueEnum;
};

// OVERVIEW
export type PerformanceScoreGeneralInfo = {
  type: LighthouseScoreEnum;
  description: {
    unknown: string;
    good: string;
    warning: string;
    alert: string;
  };
};

export enum PerformanceCardTypeEnum {
  PERFORMANCE = 'performance',
  SEO = 'seo',
  ACCESSIBILITY = 'accessibility',
  BEST_PRACTICES = 'bestPractices',
}

export enum PerformanceColorCodeEnum {
  unknown = 'unknown',
  good = 'good',
  warning = 'warning',
  alert = 'alert',
}

export type PerformanceCardInfo = {
  type: PerformanceCardTypeEnum;
  description: {
    unknown: string;
    good: string;
    warning: string;
    alert: string;
  };
};

export enum SupportMobileOS {
  ios = 'ios',
  android = 'android',
}

export enum CommunityCardContentType {
  event = 'event',
  news = 'news',
  static = 'static',
}

export type CommunityCardContent = {
  title: string;
  type: CommunityCardContentType;
  location?: string;
  subtitle?: string;
  description: string;
  ctaBtn: {
    text: string;
    onClick: () => void;
  };
};

export enum MWPUpdateStatusEnum {
  success = 'success',
  failed = 'failed',
  partialFailed = 'partial_failed',
  noUpdate = 'no_update',
  updateDeactivated = 'update_deactivated',
}

export type PreviewCardContent = {
  title: string;
  siteStatus: MWPSiteStatusDetails;
  updateStatus: MWPUpdateStatusEnum;
  lastUpdate: Date;
  nextUpdate: Date;
  imageUrl: string;
};
